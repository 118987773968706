<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">

          <AstopiaTable ref="astopiaTable" :columns="columns" :url="'/admin/chakra/list'"
                        :default-post-data="'?test=test'"
                        :axiosDataName="'result'" :sort-field="'id'" :totalDataName="'total_records'"
                        @detail-see="detailSee" @delet-see="deleteSee" @add-button="addBlog" :addButton="true" :dataPagination="true"/>
        </div>
      </div>
    </div>
  </main>

  <CreateModal
      :show="showNewContentModal"
      modalTitle="Create Chakra"
      modalWidth="50%"
      :formFields="[
                { label: 'Name', type: 'text', model: 'name', placeholder: 'Enter name' },
                { label: 'themes', type: 'text', model: 'themes', placeholder: 'Enter themes' },
                { label: 'isActive', type: 'switch', model: 'isActive', placeholder: 'Enter themes' },
                { label: 'Chakra Contents', type: 'chakraContents', model: 'chakraContents' },
              ]"
      @saveEndpoint="createObj"
      method="post"
      @hide="showNewContentModal = false"
      @success="refresh"
  />

  <EditModal
      :show="showEditModal"
      modalTitle="Edit Chakra"
      modalWidth="50%"
      :formFields="[
                { label: 'Name', type: 'text', model: 'chakra.name', placeholder: 'Enter name' },
                { label: 'themes', type: 'text', model: 'chakra.themes', placeholder: 'Enter themes' },
                { label: 'isActive', type: 'switch', model: 'chakra.isActive', placeholder: 'Enter isActive' },
                { label: 'Chakra Contents', type: 'chakraContents', model: 'chakraContents', placeholder: 'Enter Contents' },
              ]"
      :detailEndpoint="`/admin/chakra/get/${selectedObjId}`"
      @saveEndpoint="editObj"
      method="put"
      :totalDataName="'result'"
      :isOnlydata="false"
      @hide="showEditModal = false"
  />

<!--  <DeleteModal
      :show="showDeleteModal"
      :itemId="selectedDeleteObj"
      modalTitle="Delete Public Figure"
      deleteMessage="Are you sure you want to delete this public figure?"
      confirmButtonText="Yes"
      :deleteEndpoint="`/app-settings/language/delete/${selectedDeleteObj}`"
      @update:isVisible="showDeleteModal = false"
      @delete-success="refresh"
  />-->
</template>

<script>
import AstopiaTable from "../../components/Tables/AstopiaTable.vue";

export default {
  components: { AstopiaTable},
  data() {
    return {
      columns: [
        // {field: "Detail",header:"Detail", functionType: "detail", selected: true},
        {field: "chakra.name", header: "Chakra name", functionType: "res", selected: true},
        {field: "chakra.themes", header: "Chakra themes", functionType: "res", selected: true},
        {field: "chakra.isActive", header: "Chakra isActive", functionType: "res", selected: true},
        {field: "chakraContents", header: "Chakra Contents", functionType: "none", selected: true},
        {field: "Detail",header:"Detail", functionType: "crud", edit: true, delete: false, selected: true},
      ],
      showNewContentModal: false,
      showEditModal: false,
      showDeleteModal: false,
      selectedDeleteObj: null,
      language: null,
      selectedObjId: null,
      data: {
        "name": "",
        "themes": "",
        "isActive": "",
        "chakraContents": [
          {
            "id": null,
            "type": "",
            "variant": 0,
            "text": "",
            "language": "",
            "imagePath": ""
          }
        ]
      },
      dataUpdate: {
        "name": "",
        "themes": "",
        "isActive": "",
        "chakraContents": [
          {
            "type": "",
            "variant": 0,
            "text": "",
            "language": "",
            "imagePath": ""
          }
        ]
      }
    };
  },
  created() {
    this.$appAxios.get("/admin/chakra/list")
  },
  methods: {
    addBlog() {
      this.showNewContentModal = true
    },
    createObj(obj) {
      this.data.name = obj?.name;
      this.data.themes = obj?.themes;
      this.data.isActive = obj?.isActive;
      this.data.chakraContents = obj?.chakraContents;
      this.$appAxios.post("/admin/chakra/create", this.data).then(() => {
        this.refresh()
        this.showNewContentModal = false
      }).catch(err => {
        console.log(err)
      })
    },
    editObj(obj) {
      this.dataUpdate.name = obj.chakra.name;
      this.dataUpdate.themes = obj.chakra.themes;
      this.dataUpdate.isActive = obj.chakra.isActive;
      this.dataUpdate.chakraContents = obj?.chakraContents;
      this.$appAxios.put(`/admin/chakra/edit/${this.selectedObjId}`, this.dataUpdate).then(() => {
        this.refresh()
        this.showEditModal = false
      }).catch(err => {
        console.log(err)
      })
    },
    detailSee(obj) {
      this.selectedObjId = obj.chakra.id
      this.showEditModal = true
    },
    deleteSee(obj) {
      this.selectedDeleteObj = obj.chakra.id

      this.showDeleteModal = true
    },
    refresh() {
      this.$router.go(0);
    }
  }
}
</script>