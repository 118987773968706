<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>

          <AstopiaTable ref="astopiaTable" :columns="columns" :url="'/admin/public-figure/list'"
                        :default-post-data="'?test=test'"
                        :axiosDataName="'data'" :sort-field="''" :totalDataName="'totalRecords'"
                        @detail-see="detailSee" @delet-see="deleteSee" @add-button="addPublicFigure" :addButton="true" :dataPagination="false" :isOnlydata="true"/>

          <EditModal
              :show="showEditModal"
              modalTitle="Edit Public Figure"
              modalWidth="50%"
              :formFields="[
                { label: 'Name', type: 'text', model: 'user.name', placeholder: 'Enter name' },
                { label: 'Surname', type: 'text', model: 'user.surname', placeholder: 'Enter surname' },
                { label: 'Photo URL', type: 'text', model: 'user.photoUrl', placeholder: 'Enter photo URL' },
                { label: 'Language Infos Key', type: 'languageInfos', model: 'publicFigureLanguages', placeholder: 'Enter Language Infos Key', options: languages , id: 'languageKey', value: 'languageKey' },
                { label: 'Date of Birth', type: 'date', model: 'user.birthDate', placeholder: 'Select date' },
                { label: 'Hour and Minute of Birth', type: 'hour-minute', hour: 'userInfo.birthHour',  minute: 'userInfo.birthMinute',placeholder: 'Enter hour' },
                { label: 'Gender', type: 'select', model: 'user.gender', options: [
                    { text: 'All', value: 0 },
                    { text: 'Man', value: 1 },
                    { text: 'Woman', value: 2 }], value: 'value', text: 'text'
                },
                { label: 'Relationship Status', type: 'number', model: 'userInfo.relationshipStatus', placeholder: 'Enter relationship status' },
                { label: 'Occupation ID', type: 'number', model: 'userInfo.occupationId', placeholder: 'Enter occupation ID' },
                { label: 'Place of Birth country', type: 'birthCountryId', model: 'birthCountryId',options: countries , id: 'id', value: 'name'},
                { label: 'Place of Birth city', type: 'birthCityId', model: 'birthCityId',options: cities , id: 'id', value: 'name' },
                { label: 'Place of Birth state', type: 'birthDistrictId', model: 'birthDistrictId',options: states , id: 'id', value: 'name'},
                { label: 'Order', type: 'number', model: 'publicFigureInfo.order', placeholder: 'Enter order' },
                { label: 'Is Alive', type: 'switch', model: 'publicFigureInfo.isAlive' },
                { label: 'Is Active', type: 'switch', model: 'publicFigureInfo.isActive' }
              ]"
              :detailEndpoint="`/admin/public-figure/${selectedObjId}`"
              @saveEndpoint="editObj"
              method="put"
              :totalDataName="'data'"
              :isOnlydata="true"
              @hide="showEditModal = false"
          />


          <CreateModal
              :show="showCreateModal"
              modalTitle="Create Public Figure"
              modalWidth="50%"
              :formFields="[
                { label: 'Name', type: 'text', model: 'name', placeholder: 'Enter name' },
                { label: 'Surname', type: 'text', model: 'surname', placeholder: 'Enter surname' },
                { label: 'Photo URL', type: 'text', model: 'photoUrl', placeholder: 'Enter photo URL' },
                { label: 'Language Infos Key', type: 'languageInfos', model: 'languageInfos', options: languages , id: 'languageKey', value: 'languageKey'},
                { label: 'Date of Birth', type: 'date', model: 'dateOfBirth', placeholder: 'Select date' },
                { label: 'Hour and Minute of Birth', type: 'hour-minute', hour: 'hourOfBirth', minute: 'minuteOfBirth', placeholder: 'Enter hour' },
                { label: 'Gender', type: 'select', model: 'gender', options: [{ 'id': 0, 'value': 'All' }, { 'id': 1, 'value': 'Man' }, { 'id': 2, 'value': 'Woman' }], id: 'id', value: 'value'},
                { label: 'Relationship Status', type: 'drop', model: 'relationshipStatus',options: relationships , id: 'id', value: 'name'},
                { label: 'Occupation ID', type: 'drop', model: 'occupationId',options: occupations , id: 'id', value: 'name'},
                { label: 'Place of Birth country', type: 'country', model: 'country',options: countries , id: 'id', value: 'name'},
                { label: 'Place of Birth city', type: 'city', model: 'city',options: cities , id: 'id', value: 'name' },
                { label: 'Place of Birth state', type: 'state', model: 'state',options: states , id: 'id', value: 'name'},
                { label: 'Order', type: 'number', model: 'order', placeholder: 'Enter order' },
                { label: 'Is Alive', type: 'switch', model: 'isAlive' },
                { label: 'Is Active', type: 'switch', model: 'isActive' }
              ]"
              @saveEndpoint="createObj"
              method="post"
              @hide="showCreateModal = false"
              @success="refreshTable"
          />



          <DeleteModal
              :show="showDeleteModal"
              :itemId="selectedDeleteId"
              modalTitle="Delete Public Figure"
              deleteMessage="Are you sure you want to delete this public figure?"
              confirmButtonText="Yes"
              :deleteEndpoint="`/admin/public-figure/delete/${selectedDeleteId}`"
              @update:isVisible="showDeleteModal = false"
              @delete-success="refreshTable"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import AstopiaTable from "@/components/Tables/AstopiaTable.vue";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import Toast from 'primevue/toast';
import {mapGetters} from "vuex";
import dist from "vue-sweetalert2/dist";

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  name: "Users",
  components: {
    Header,
    Toast,
    AstopiaTable
  },
  data() {
    const schema = {
      price: "required|numeric|regex",
    };

    return {
      loading: false,
      totalRecords: 0,
      content: [],
      filters: {
        global: {value: null},
        name: {value: null},
        isPublicFigure: {value: true}
      },
      contentLazyParams: {},
      schema,
      houses: [],
      signs: [],
      genders: [{id: 1, name: "Man"}, {id: 2, name: "Woman"}, {id: 3, name: "Other"}],
      showEditBirthdateModal: false,
      selectedObjId: null,
      showEditModal: false,
      showCreateModal: false,
      showDeleteModal: false,
      selectedDeleteId: null,
      editUserModel: {},
      messages: [],
      messageCount: 0,
      saveButtonLoading: false,
      showPublicFigureModal: false,
      newPublicFigureModel: {},
      languages: [],
      countries: [],
      cities: [],
      states: [],
      columns: [
        // {field: "Detail",header:"Detail", functionType: "detail", selected: true},
        {field: "user.name", header: "User Name", functionType: "res", selected: true},
        {field: "user.surname", header: "User surname", functionType: "res", selected: true},
        {field: "userInfo.userId", header: "User userId", functionType: "res", selected: true},
        {field: "Actions", header: "Actions", functionType: "crud", edit: true, delete: true, selected: true},
      ],
      data:{
        "name": "string",
        "surname": "string",
        "photoUrl": "string",
        "dateOfBirth": "string",
        "hourOfBirth": 0,
        "minuteOfBirth": 0,
        "gender": 0,
        "relationshipStatus": 0,
        "occupationId": 0,
        "placeOfBirth": {
          "city": 0,
          "country": 0,
          "state": 0
        },
        "order": 0,
        "isAlive": true,
        "isActive": true,
        "languageInfos": [
          {
            "key": "string",
            "isActive": true
          }
        ]
      },
      dataUpdate:{
        "name": "string",
        "surname": "string",
        "photoUrl": "string",
        "dateOfBirth": "string",
        "hourOfBirth": 0,
        "minuteOfBirth": 0,
        "gender": 0,
        "relationshipStatus": 0,
        "occupationId": 0,
        "placeOfBirth": {
          "city": 0,
          "country": 0,
          "state": 0
        },
        "order": 0,
        "isAlive": true,
        "isActive": true,
        "languageInfos": [
          {
            "key": "string",
            "isActive": true
          }
        ]
      }
    };
  },
  mounted() {
    this.$appAxios.get("admin/app-settings/language/list?test=test&offset=0&limit=10000")
        .then(resp => {
          this.languages = resp.data.languages
        })
        .catch(err => {
          console.log(err)
        });
  },
  computed: {
    dist() {
      return dist
    },
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds']),
  },
  methods: {
    openEditModal(rowData) {
      console.log("rowData",rowData)
      //copy object
      this.editUserModel = rowData;
      this.showEditBirthdateModal = true;
    },
    deleteSee(report) {
      this.selectedDeleteId = report.publicFigureInfo.id

      this.showDeleteModal = true
    },
    detailSee(obj) {
      this.selectedObjId = obj.publicFigureInfo.id
      this.showEditModal = true
    },
    editObj(obj) {
      this.dataUpdate.name = obj.user.name;
      this.dataUpdate.surname = obj.user.surname;
      this.dataUpdate.photoUrl = obj.user.photoUrl;
      this.dataUpdate.dateOfBirth = obj?.user.birthDate;
      this.dataUpdate.hourOfBirth = obj?.userInfo.birthHour;
      this.dataUpdate.minuteOfBirth = obj?.userInfo.birthMinute;
      this.dataUpdate.gender = obj?.user.gender;
      this.dataUpdate.relationshipStatus = obj?.userInfo.relationshipStatus;
      this.dataUpdate.occupationId = obj?.userInfo.occupationId;
      this.dataUpdate.placeOfBirth.country = obj?.userInfo.birthCountryId;
      this.dataUpdate.placeOfBirth.city = obj?.userInfo.birthCityId;
      this.dataUpdate.placeOfBirth.state = obj?.userInfo.birthDistrictId;
      this.dataUpdate.order = obj?.order;
      this.dataUpdate.isAlive = obj?.isAlive;
      this.dataUpdate.isActive = obj?.isActive;
// Mevcut languageInfos dizisini koruyun
      this.dataUpdate.languageInfos = [];

// obj?.publicFigureLanguages dizisini döngüye al
      obj?.publicFigureLanguages.forEach(item => {
        // 'language' veya 'key' anahtarına göre uygun şekilde ekle
        this.dataUpdate.languageInfos.push({
          key: item.language || item.key, // 'language' veya 'key' değerini kullan
          isActive: item.isActive
        });
      });

      this.$appAxios.put(`/admin/public-figure/update/${this.selectedObjId}`, this.dataUpdate).then(response => {
        console.log(response)

        this.refreshTable()
        this.showEditModal = false
      }).catch(err => {
        console.log(err)
      })
    },
    createObj(obj) {
      this.data.name = obj.name;
      this.data.surname = obj.surname;
      this.data.photoUrl = obj.photoUrl;
      this.data.dateOfBirth = obj?.dateOfBirth;
      this.data.hourOfBirth = obj?.hourOfBirth;
      this.data.minuteOfBirth = obj?.minuteOfBirth;
      this.data.gender = obj?.gender;
      this.data.relationshipStatus = obj?.relationshipStatus;
      this.data.occupationId = obj?.occupationId;
      this.data.placeOfBirth.country = obj?.country;
      this.data.placeOfBirth.city = obj?.city;
      this.data.placeOfBirth.state = obj?.state;
      this.data.order = obj?.order;
      this.data.isAlive = obj?.isAlive;
      this.data.isActive = obj?.isActive;
      this.data.languageInfos = obj?.languageInfos;
      this.$appAxios.post("/admin/public-figure/create", this.data).then(response => {
        console.log(response)

        this.refreshTable()
        this.showCreateModal = false
      }).catch(err => {
        console.log(err)
      })
    },
    addPublicFigure() {
      this.showCreateModal = true
    },
    refreshTable() {
      this.$refs.astopiaTable.fetchData();
    }
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>
  