<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xl-12 col-12 m-0 no-max-w home-div">
          <Toast/>

          <DataTable
              :value="images"
              :lazy="true"
              dataKey="id"
              ref="dt"
              :totalRecords="imagesTotalRecords"
              :loading="loading"
              @page="onImagesPage($event)"
              @sort="onImagesSort($event)"
              @filter="onImagesFilter($event)"
              @row-expand="onImagesExpand($event)"
              responsiveLayout="scroll"
              class="p-datatable-striped"
              v-model:filters="filters"
              v-model:expandedRows="expandedRows"
              filterDisplay="menu"
              removableSort
              :globalFilterFields="['name', 'isActive']"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          >
            <template #header>
              <div class="flex justify-content-between">
                <div class="flex mb-4">
                  <Button
                      type="button"
                      icon="pi pi-plus"
                      label="Add Folder"
                      class="p-button-info d-inline me-4"
                      @click="showAddFolderModal = true"
                  />

                  <Button
                      type="button"
                      icon="pi pi-filter-slash"
                      label="Clear"
                      class="p-button-outlined d-inline"
                      @click="clearFilter()"
                  />
                </div>
                <span class="fs-2 fw-bold">Images</span>
              </div>
            </template>

            <template #empty> No images found.</template>
            <template #loading> Loading images data. Please wait.</template>

            <Column :expander="true" :headerStyle="{ width: '1rem' }"/>
            <Column field="folderName" header="Folder Name" ref="folderName"></Column>
            <Column field="key" header="Key" ref="key"></Column>
<!--            <Column header="Actions">
              <template #body="slotProps">
                <Button v-tooltip.left="'Delete'" icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                        @click="deleteSee(slotProps.data)" />
              </template>
            </Column>-->
            <!-- <Column
              field="actions"
              header="Actions"
              ref="actions"
            >
              <template #body="slotProps">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-warning p-button-sm mr-2"
                  @click="showEditTutor(slotProps.data.id)"
                  v-if="slotProps.data.roles.includes('Tutor')"
                />
                <Button
                  icon="pi pi-plus"
                  class="p-button-primary p-button-sm mr-2"
                  @click="addProgram(slotProps.data.id)"
                  v-if="slotProps.data.roles.includes('Tutor')"
                />
              </template>
            </Column> -->
            <template #expansion="">
              <DataTable
                  :value="folderImages"
                  :lazy="true"
                  dataKey="id"
                  :rows="5"
                  ref="dt"
                  responsiveLayout="scroll"
                  class="p-datatable-striped"
                  :paginator="true"
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  :rowsPerPageOptions="[5, 10, 20, 50, 100]"
                  :totalRecords="folderImagesTotalRecords"
                  :loading="loading"
                  @page="onFolderImagesPage($event)"
              >
                <template #header>
                  <div class="table-header">
                    <FileUpload name="img[]" accept="image/*" mode="basic" chooseLabel="Upload" :maxFileSize="10000000"
                                :fileLimit="1" :customUpload="true" @uploader="imageUploader"/>

                  </div>
                </template>
                <Column field="key" header="Image Name" ref="key"></Column>
                <Column field="size" header="Size" ref="size">
                  <template #body="slotProps">
                    <span class="m-0">{{ bytesToSize(slotProps.data.size) }}</span>
                  </template>
                </Column>
                <Column field="lastModified" header="Last Modified" ref="lastModified">
                  <template #body="slotProps">
                    <span class="m-0">{{ formatDate(slotProps.data.lastModified) }}</span>
                  </template>
                </Column>

                <Column field="imageUrl" header="Preview" ref="key">
                  <template #body="slotProps">
                    <Button label="Show" icon="pi pi-image" iconPos="right"
                            @click="showPreview(slotProps.data.imageUrl)"/>
                  </template>
                </Column>

                <Column field="imageUrl" header="Copy URL" ref="key">
                  <template #body="slotProps">
                    <Button v-tooltip="'Copy to clipboard'" icon="pi pi-save"
                            class="p-button-outlined p-button-raised p-button-rounded" iconPos="right"
                            @click="copyToClipboard(slotProps.data.imageUrl)"/>
                  </template>
                </Column>

<!--                <Column header="Actions">
                  <template #body="slotProps">
                    <Button v-tooltip.left="'Delete'" icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                            @click="deleteSeeFile(slotProps.data)" />
                  </template>
                </Column>-->
              </DataTable>
            </template>
          </DataTable>
        </div>
        <Dialog
            v-model:visible="showPreviewModal"
            modal
            :style="{ width: '35vw' }"
        >
          <img :src="previewUrl" alt="" class="image-preview">
        </Dialog>
        <Dialog
            v-model:visible="showAddFolderModal"
            modal
            :style="{ width: '35vw' }"
        >
          <InputText v-model="newFolderName" placeholder="Folder Name"/>
          <template #footer>
            <Button
                label="Add"
                icon="pi pi-check"
                class="p-button-success"
                @click="createNewFolder()"
            />
            <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-danger"
                @click="showAddFolderModal = false"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </main>

  <DeleteModal
      :show="showDeleteModal"
      :itemId="selectedDeleteName"
      modalTitle="Delete Public Figure"
      deleteMessage="Are you sure you want to delete this public figure?"
      confirmButtonText="Yes"
      :deleteEndpoint="`/admin/image/delete-folder/${selectedDeleteName}`"
      @update:isVisible="showDeleteModal = false"
      @delete-success="refreshTable"
  />
  <DeleteModal
      :show="fileshowDeleteModal"
      :itemId="selectedDeleteName"
      modalTitle="Delete Public Figure"
      deleteMessage="Are you sure you want to delete this public figure?"
      confirmButtonText="Yes"
      :deleteEndpoint="`/admin/image/delete-image/${selectfolderName}/${selectfileName}`"
      @update:isVisible="fileshowDeleteModal = false"
      @delete-success="refreshTable"
  />

</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import InputText from "primevue/inputtext";
import FileUpload from 'primevue/fileupload';
import Dialog from "primevue/dialog";
import Toast from 'primevue/toast';
import Header from "@/components/Header.vue";

export default {
  name: "Images",
  components: {
    DataTable,
    Column,
    InputText,
    Button,
    Dialog,
    FileUpload,
    Toast,
    Header,
  },
  data() {
    return {
      loading: false,
      imagesTotalRecords: 0,
      folderImagesTotalRecords: 0,
      images: [],
      folderImages: [],
      filters: {
        global: {value: null},
      },
      imagesLazyParams: {},
      folderImagesLazyParams: {},
      expandedRows: [],
      showPreviewModal: false,
      previewUrl: null,
      newFolderName: "",
      showAddFolderModal: false,
      showDeleteModal: false,
      selectedDeleteName: null,
      selectfolderName: null,
      selectfileName: null,
      fileshowDeleteModal: false,
    };
  },
  mounted() {
    this.imagesLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    this.folderImagesLazyParams = {
      first: 0,
      rows: 5,
    }
    this.getImages();
  },
  computed: {},
  methods: {
    getImages() {
      this.loading = true;

      var filters = {};

      for (const [key] of Object.entries(this.imagesLazyParams.filters)) {
        filters[key] = this.imagesLazyParams.filters[key].value;
      }

      this.$appAxios
          .post("admin/image/list", {
            first: this.imagesLazyParams.first,
            rows: this.imagesLazyParams.rows,
            sortField: this.imagesLazyParams.sortField,
            sortOrder: this.imagesLazyParams.sortOrder,
            page: this.imagesLazyParams.page,
            filters: JSON.stringify(filters),
          })
          .then((resp) => {
            this.images = resp.data.data;
            this.imagesTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    getFolderImages() {
      this.$appAxios
          .post("admin/image/folderImages", {
            first: this.folderImagesLazyParams.first,
            rows: this.folderImagesLazyParams.rows,
            id: this.folderImagesLazyParams.id,
          })
          .then((resp) => {
            this.folderImages = resp.data.data;
            this.folderImagesTotalRecords = resp.data.totalRecords
          });
    },
    onImagesPage(event) {
      this.imagesLazyParams = event;
      this.imagesLazyParams.filters = this.filters;
      this.getImages();
    },
    onImagesSort(event) {
      this.imagesLazyParams = event;
      this.imagesLazyParams.filters = this.filters;
      this.getImages();
    },
    onImagesFilter() {
      this.imagesLazyParams.filters = this.filters;
      this.getImages();
    },
    onFolderImagesPage(event) {
      this.folderImagesLazyParams = {...this.folderImagesLazyParams, ...event};
      this.getFolderImages();
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.getImages();
    },
    onImagesExpand(event) {
      //reset filters
      this.folderImagesLazyParams.first = 0;
      this.folderImagesLazyParams.rows = 5;

      this.folderImagesLazyParams.id = event.data.folderName;
      this.getFolderImages();
    },
    formatDate(dateString) {
      if (dateString == null) {
        return "";
      }

      const date = dayjs(dateString);
      return date.format("DD/MM/YYYY HH:mm");
    },
    bytesToSize(bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return '0 Byte';
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    showPreview(url) {
      this.showPreviewModal = true;
      this.previewUrl = url;
    },
    imageUploader(event) {

      var file = event.files[0];

      let formData = new FormData();
      formData.append("file", file);

      this.$appAxios
          .post("admin/image/uploadImage", formData, {
            headers: {
              'Folder-Name': this.folderImagesLazyParams.id
            }
          })
          .then(() => {
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Image was uploaded successfully',
              life: 3000
            });
            this.getFolderImages();
          }).catch(() => {
        this.$toast.add({severity: 'error', summary: 'Error', detail: 'Could not upload the image', life: 3000});
      });
    },
    copyToClipboard(url) {

      navigator.clipboard.writeText(url).then(() => {

        this.$toast.add({severity: 'success', summary: 'Success', detail: 'URL was copied successfully', life: 3000});

      }).catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Could not copy the URL to clipboard',
          life: 3000
        });
      });
    },
    createNewFolder() {
      this.$appAxios
          .post("admin/image/create-folder", {
            folderName: this.newFolderName,
          })
          .then(() => {
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Folder was created successfully',
              life: 3000
            });
            this.getImages();
          }).catch(() => {
        this.$toast.add({severity: 'error', summary: 'Error', detail: 'Could not create the folder', life: 3000});
      });
      this.showAddFolderModal = false;
    },
    deleteSee(obj) {
      this.selectedDeleteName = obj.folderName

      this.showDeleteModal = true
    },
    deleteSeeFile(obj) {
      const keyParts = obj.key.split("/");
      this.selectfolderName = keyParts[0];
      this.selectfileName = keyParts[1];


      this.fileshowDeleteModal = true
    },
    refreshTable() {
      this.showDeleteModal = false
      this.getImages();
    }
  }
}
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}

.p-inputtext {
  width: 250px;
}

.form-group .p-inputtext {
  width: 100%;
}

.p-inputnumber-input {
  width: 100%;
}

.image-preview {
  width: 100%;
  height: auto;
}
</style>
  