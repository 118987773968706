<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>

          <DataTable
              :value="content"
              :lazy="true"
              :paginator="true"
              v-model:expandedRows="expandedRows"
              @rowExpand="onExpand"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              :rowsPerPageOptions="[5, 10, 20, 50, 100]"
              dataKey="id"
              :rows="10"
              ref="dt"
              :totalRecords="totalRecords"
              :loading="loading"
              @page="onPage($event)"
              @sort="onSort($event)"
              @filter="onFilter($event)"
              responsiveLayout="scroll"
              class="p-datatable-striped"
              v-model:filters="filters"
              filterDisplay="menu" removableSort
              :globalFilterFields="['name', 'isActive']"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
              <div class="d-flex justify-content-between">
                <div></div>
                <span class="p-input-icon-left">
                  <i class="pi pi-search"/>
                  <InputText v-model="filters['global'].value" placeholder="Keyword Search" @change="getContent"/>
                </span>
              </div>
            </template>

            <template #empty> No user found.</template>
            <template #loading> Loading user data. Please wait.</template>
            <Column :expander="true" headerStyle="width: 3rem"/>
            <Column field="name" header="Name" ref="name">
              <template #body="slotProps">
                <span v-if="slotProps.data.name" class="m-0">{{ slotProps?.data?.name }}</span>
              </template>
            </Column>
            <Column field="surname" header="Surname" ref="surname"/>
            <Column field="id" header="Id" ref="id"/>
            <Column field="email" header="Email" ref="email"/>
            <Column field="gender" header="Gender" ref="gender">
              <template #body="slotProps">
                <span class="m-0">{{ this.genders.find(x => x.id == slotProps.data.gender)?.name }}</span>
              </template>
            </Column>
            <Column field="birthDate" header="Birth Date" ref="birthDate">
              <template #body="slotProps">
                <span class="m-0">{{ formatDate(slotProps.data.birthDate) }}</span>
              </template>
            </Column>
            <Column field="subscription" header="Subscription" ref="subscription">
              <template #body="slotProps">
                <div v-if="slotProps.data.subscription">{{ slotProps.data.subscription }}</div>
                <div v-else>
                  <Button class="p-button-primary p-button-sm me-2 my-2" v-tooltip.bottom="'Fetch Subscription Info'"
                          icon="pi pi-download" @click="fetchSubscription(slotProps.data.id)"/>
                </div>
              </template>
            </Column>


            <Column field="registerDate" header="Register Date" ref="registerDate">
              <template #body="slotProps">
                <span class="m-0">{{ formatDate(slotProps.data.registerDate, "DD/MM/YYYY hh:mm") }}</span>
              </template>
            </Column>
            <Column field="devices" header="Devices" ref="devices">
              <template #body="slotProps">
                <ScrollPanel style="width: 100%; height: 100px" class="custombar">
                  <div v-for="d in slotProps.data.devices" :key="d">
                    <div>{{ d.brand }} / {{ d.model }}</div>
                  </div>
                </ScrollPanel>
              </template>
            </Column>
            <Column field="actions" header="Actions" ref="actions">
              <template #body="slotProps">
                <Button class="p-button-primary p-button-sm me-2 my-2" v-tooltip.bottom="'Edit User'"
                        icon="pi pi-pencil" @click="openEditUserModal(slotProps.data.id)"/>
              </template>
            </Column>

            <template>
              <div>
                <Column field="birthdateLog" header="Birthdate Log" ref="birthdateLog">
                  <template #body="slotProps">
                    <Button class="p-button-primary p-button-sm me-2 my-2" v-tooltip.bottom="'Fetch Birthdate Log '"
                            icon="pi pi-eye" @click="openModal(slotProps.data.id)"/>
                  </template>
                </Column>
                <Dialog v-model:visible="displayModal" :modal="true" :style="{width: '50vw'}" :header="'Birthdate Log'">
                  <div style="display: flex; flex-wrap: wrap;">
                    <div v-for="(d, index) in currentBirthdateLog" :key="d"
                         style="display: flex; flex-direction: row; width: 300px; margin-right: 2rem;">
                      <div>
                        <h5>Değiştirme Sayısı: {{ index + 1 }}</h5>
                        <div style="margin-top: 1rem;">
                          <h6>Old Birth Date</h6>
                          <p>{{ formatDate(d.oldBirthDate, "DD/MM/YYYY hh:mm") }}</p>
                        </div>
                        <div style="margin-top: 1rem;">
                          <h6>Request Date</h6>
                          <p>{{ formatDate(d.requestDate, "DD/MM/YYYY hh:mm") }}</p>
                        </div>
                        <div style="margin-top: 1rem;">
                          <h6>New Birth Date</h6>
                          <p>{{ formatDate(d.newBirthDate, "DD/MM/YYYY hh:mm") }}</p>
                        </div>
                      </div>
                      <Divider layout="vertical"/>
                    </div>
                  </div>
                </Dialog>

              </div>
            </template>

            <template>
              <div>
                <Column field="signsDetail" header="Signs Detail" ref="signsDetail">
                  <template #body="slotProps">
                    <Button class="p-button-primary p-button-sm me-2 my-2" v-tooltip.bottom="'Signs Detail'"
                            icon="pi pi-eye" @click="signsDetailOpenModal(slotProps.data.id)"/>
                  </template>
                </Column>
                <Dialog v-model:visible="signsDetailModal" :modal="true" :style="{width: '50vw'}" :header="'Astrological Data'">
                  <div style="display: flex;justify-content: center;">
                    <div class="modal-overlay" @click="signsDetailModal=false">
                      <div class="modal-content" @click.stop>
                        <div class="natal-chart">
                          <img :src="currentSignsDetailLog.natalChart" alt="Natal Chart" />
                        </div>
                        <div class="planet-cards">
                          <div v-for="(planet, key) in currentSignsDetailLog.planets" :key="key" class="planet-card">
                            <h6>{{ planet.name }} - {{ planet.signName }}</h6>
                            <div class="planet-images">
                              <img :src="planet.planetPhotoUrl" :alt="`${planet.name} photo`" />
                              <img :src="planet.signPhotoUrl" :alt="`${planet.signName} sign photo`" />
                            </div>
<!--                            <p>Sign: {{ planet.sign }}</p>-->
                          </div>
                        </div>
                        <Button class="p-button-primary w-100 me-2 my-2" @click="signsDetailModal=false">Close</Button>
                      </div>
                    </div>
                  </div>
                </Dialog>

              </div>
            </template>

            <template #expansion="slotProps">
              <DataTable
                  :value="slotProps.data.expandedTable"
                  :lazy="true"
                  dataKey="id"
                  :first="subContentsLazyParams.first"
                  :rows="subContentsLazyParams.rows"
                  ref="dt"
                  responsiveLayout="scroll"
                  class="p-datatable-striped"
                  :paginator="true"
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  :rowsPerPageOptions="[5, 10, 20, 50, 100]"
                  :totalRecords="subContentsTotalRecords"
                  :loading="loading"
                  @page="onSubContentPage($event)">
                <template #header>
                  Follow Users
                </template>
                <Column field="name" header="Name" ref="name">
                  <template #body="slotProps">
                    <span v-if="slotProps.data.name" class="m-0">{{ slotProps.data.name }}</span>
                  </template>
                </Column>
                <Column field="surname" header="Surname" ref="surname"/>
                <Column field="email" header="Email" ref="email"/>
                <Column field="gender" header="Gender" ref="gender">
                  <template #body="slotProps">
                    <span class="m-0">{{ this.genders.find(x => x.id == slotProps.data.gender).name }}</span>
                  </template>
                </Column>
                <Column field="birthDate" header="Birth Date" ref="birthDate">
                  <template #body="slotProps">
                    <span class="m-0">{{ formatDate(slotProps.data.birthDate) }}</span>
                  </template>
                </Column>
                <Column field="registerDate" header="Register Date" ref="registerDate">
                  <template #body="slotProps">
                    <span class="m-0">{{ formatDate(slotProps.data.registerDate, "DD/MM/YYYY hh:mm") }}</span>
                  </template>
                </Column>
                <Column field="actions" header="Actions" ref="actions">
                  <template #body="slotProps">
                    <Button class="p-button-primary p-button-sm me-2 my-2" v-tooltip.bottom="'Edit User'"
                            icon="pi pi-pencil" @click="openEditUserModal(slotProps.data.id, true)"/>
                  </template>
                </Column>
              </DataTable>
            </template>
          </DataTable>


          <Dialog header="Edit User" v-model:visible="showEditBirthdateModal" modal :style="{ 'width': '40%' }">
            <div class="col-12">
              <label class="d-block">Birthdate</label>
              <Calendar v-model="editUserModel.birthDate" hourFormat="24" dateFormat="dd/mm/yy"/>
            </div>
            <div class="row mt-4 d-flex">
              <label style="width: 20%" class="d-block">Birth hour</label>

              <select style="width: 20%" v-model="editUserModel.birth_hour"
                      v-bind:disabled="editUserModel.unknownBirthTime">
                <option v-for="hour in formattedHours" :key="hour" :value="hour">
                  {{ hour }}
                </option>
              </select>
              <span style="width: 10px">:</span>
              <select style="width: 20%" v-model="editUserModel.birth_min"
                      v-bind:disabled="editUserModel.unknownBirthTime">
                <option v-for="minute in formattedMinutes" :key="minute" :value="minute">
                  {{ minute }}
                </option>
              </select>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <label class="d-block">Country</label>
                <Dropdown v-model="editUserModel.countryId" :options="countries" optionLabel="name" optionValue="id"
                          placeholder="Select a Country" @change="getCities" filter/>
              </div>

              <div class="col-6">
                <label class="d-block">City</label>
                <Dropdown v-model="editUserModel.cityId" :options="cities" optionLabel="name" optionValue="id"
                          placeholder="Select a City" @change="getDistricts" filter/>
              </div>

              <div class="col-6 mt-4" v-if="editUserModel.districtId && selectedCityHasState && districts?.length > 0">
                <label class="d-block">District</label>
                <Dropdown v-model="editUserModel.districtId" :options="districts" optionLabel="name" optionValue="id"
                          placeholder="Select a District" filter/>
              </div>

            </div>

            <div class="modal-footer mt-5">
              <button type="button" class="btn btn-default" @click="showEditBirthdateModal = false">
                Close
              </button>
              <Button label="Save" icon="pi pi-save" :loading="saveButtonLoading" iconPos="left"
                      @click="saveUserUpdate"/>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import Toast from 'primevue/toast';

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  name: "Users",
  components: {
    Header,
    DataTable,
    Column,
    Toast,
  },
  data() {
    const schema = {
      price: "required|numeric|regex",
    };

    return {
      loading: false,
      expandedRows: {},
      totalRecords: 0,
      subContentsTotalRecords: 0,
      content: [],
      filters: {
        global: {value: null},
        name: {value: null},
      },
      contentLazyParams: {},
      subContentsLazyParams: {
        first: 0,
        rows: 5,
      },
      schema,
      houses: [],
      signs: [],
      genders: [{id: 1, name: "Man"}, {id: 2, name: "Woman"}, {id: 3, name: "Other"}],
      showEditBirthdateModal: false,
      editUserModel: {},
      messages: [],
      messageCount: 0,
      saveButtonLoading: false,
      countries: [],
      cities: [],
      districts: [],
      subContents: [],
      unknownBirthTime: false,
      displayModal: false,
      signsDetailModal: false,
      selectedCityHasState: false,
      currentBirthdateLog: [],
      currentSignsDetailLog: {},
    };
  },
  mounted() {
    this.contentLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    this.subContentsLazyParams = {
      first: 0,
      rows: 5,
    }
    this.getContent();

    this.$appAxios.get("global/countries")
        .then(resp => {
          this.countries = resp.data.response
        })
        .catch(err => {
          console.log(err)
        });
  },
  computed: {
    formattedHours() {
      return Array.from({length: 24}, (_, i) => i);
    },
    formattedMinutes() {
      return Array.from({length: 60}, (_, i) => i);
    },
    /*    hasState() {
          const selectedCountry = this.countries.find(x => x.id === this.editUserModel.countryId);
          return selectedCountry ? selectedCountry.hasState : false;
        }*/
  },
  watch: {
    "editUserModel.unknownBirthTime": function (newValue) {
      if (newValue) {
        this.editUserModel.birth_hour = "12";
        this.editUserModel.birth_min = "00";
      }
    },
  },
  methods: {
    getContent() {
      this.loading = true;

      var filters = {};

      for (const [key] of Object.entries(this.contentLazyParams.filters)) {
        filters[key] = this.contentLazyParams.filters[key].value;
      }

      this.$appAxios
          .post("admin/user/list", {
            first: this.contentLazyParams.first,
            rows: this.contentLazyParams.rows,
            sortField: this.contentLazyParams.sortField,
            sortOrder: this.contentLazyParams.sortOrder,
            page: this.contentLazyParams.page,
            filters: JSON.stringify(filters),
          })
          .then((resp) => {
            this.content = resp.data.data;
            this.totalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onExpand(event) {
      // this.subContentLazyParams.id = event.data.id;
      this.getSubContents(event.data.id);
    },
    onSubContentPage(event) {
      this.subContentsLazyParams.first = event.first;
      this.subContentsLazyParams.rows = event.rows;
      this.getSubContents();
    },
    getSubContents(id) {
      if (!id) return;

      this.$appAxios
          .post("admin/user/follow/list/" + id, {
            first: this.subContentsLazyParams.first,
            rows: this.subContentsLazyParams.rows,
          })
          .then((resp) => {
            this.subContents = resp.data.data;
            this.content.find(d => d?.id === id).expandedTable = resp.data.data
            this.subContentsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onPage(event) {
      this.contentLazyParams = event;
      this.contentLazyParams.filters = this.filters;
      this.getContent();
    },
    onSort(event) {
      this.contentLazyParams = event;
      this.contentLazyParams.filters = this.filters;
      this.getContent();
    },
    onFilter() {
      this.contentLazyParams.filters = this.filters;
      this.getContent();
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.getContent();
    },
    formatDate(dateString, format = "DD/MM/YYYY") {
      if (dateString == null) {
        return "";
      }

      const date = dayjs(dateString);
      return date.format(format);
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    openEditUserModal(id, isSubContent = false) {
      this.editUserModel.id = id;


      var userEntry = null;
      if (isSubContent) {
        userEntry = this.subContents.find(x => x.id == id);
      } else {
        userEntry = this.content.find(x => x.id == id);
      }

      // var birthDate = dayjs(userEntry.fullBirthDate).format("DD/MM/YYYY HH:mm");
      this.editUserModel.birthDate = userEntry.birthDate;
      this.editUserModel.birth_hour = userEntry.birthHour;
      this.editUserModel.birth_min = userEntry.birthMinute;
      this.editUserModel.cityId = userEntry.birthCity;
      this.editUserModel.countryId = userEntry.birthCountry;
      this.editUserModel.districtId = userEntry.birthDistrict;


      this.cities = this.getCities({value: userEntry.birthCountry});
      if (userEntry.birthCity) {
        this.selectedCityHasState = true;
        this.districts = this.getDistricts({value: userEntry.birthCity});

      }


      this.showEditBirthdateModal = true;
    },
    saveUserUpdate() {
      //change date to utc without converting
      let combinedDateTime = dayjs(this.editUserModel.birthDate)
          .hour(this.editUserModel.birth_hour)
          .minute(this.editUserModel.birth_min)
          .second(0)
          .millisecond(0)
          .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";

      // Update editUserModel
      this.editUserModel.birthDate = combinedDateTime;
      this.saveButtonLoading = true;
      this.$appAxios
          .put("admin/user/update-user", this.editUserModel)
          .then(() => {
            this.showEditBirthdateModal = false;
            this.getContent();
            this.getSubContents();
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'User updated successfully', life: 3000});
            this.saveButtonLoading = false;
          })
          .catch(() => {
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Could not update user.', life: 3000});
            this.saveButtonLoading = false;
          });
    },
    getCities(event) {
      var countryId = event.value;
      this.$appAxios.get("global/cities/" + countryId)
          .then(resp => {
            this.cities = resp.data.response;
          })
          .catch(err => {
            console.log(err)
          });
    },
    getDistricts(event) {
      var cityId = event.value;
      this.$appAxios.get("global/districts/" + cityId)
          .then(resp => {
            this.districts = resp.data.response;
          })
          .catch(err => {
            console.log(err)
          });
    },
    async fetchSubscription(id) {
      var content = this.content.find(x => x.id == id);
      try {
        var data = await this.$appAxios.get("admin/user/subscription/" + id);
        content.subscription = data.data.name;
      } catch (error) {
        content.subscription = "No subscription";
      }

    },
    async fetchBirthdateLog(id) {
      var content = this.content.find(x => x.id == id);
      try {
        var data = await this.$appAxios.get("admin/user/birthdate-log/" + id);
        content.birthdateLog = data.data;
      } catch (error) {
        content.birthdateLog = "No Birthdate Log";
      }
    },
    async openModal(id) {
      await this.fetchBirthdateLog(id);
      this.currentBirthdateLog = this.content.find(x => x.id == id).birthdateLog;
      this.displayModal = true;
    },
    async signsDetailOpenModal(id) {
      this.$appAxios.get("admin/user/basic-planets/" + id).then(resp => {
        console.log("API yanıtı:", resp); // API yanıtını kontrol edin
        this.currentSignsDetailLog = resp.data.response;
        console.log("Güncel işaret detayları:", this.currentSignsDetailLog);
        this.signsDetailModal = true; // Modalı açmak için bu değeri güncelleyin
        console.log("Modal durumu:", this.signsDetailModal);
      }).catch(error => {
        console.error("API isteği sırasında hata:", error); // Olası hataları yakalayın
      });
    }
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}


.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  text-align: center;
}

.natal-chart img {
  width: 100%;
  max-width: 300px;
  margin: 20px 0;
}

.planet-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.planet-card {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.planet-images img {
  width: 40px;
  height: 40px;
  margin: 5px;
}

button {
  margin-top: 20px;
}
</style>