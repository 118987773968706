<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>

          <div class="card card-custom gutter-b w-100">
            <div class="card-header d-flex">
              <Button
                  label="New"
                  icon="pi pi-plus"
                  iconPos="left"
                  @click="openNewModal()"
              />
              <FileUpload
                  mode="basic"
                  class="ms-2"
                  name="file[]"
                  accept=".xlsx"
                  :file-limit="1"
                  :customUpload="true"
                  @uploader="uploader"
                  :maxFileSize="5000000"
                  choose-label="Choose File"
                  upload-label="Upload"
                  cancel-label="Cancel"
              />
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <FullCalendar :options="calendarOptions" @datesRender="handleWeekChange" ref="calendar"
                                :events="events"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog header="Edit Session" v-model:visible="showEditModal" modal :style="{ width: '35vw' }">
      <div class="row mb-3 mt-3">
        <div class="col-md-12 form-group">
          <label class="d-block">DATE:</label>
          <Datepicker v-model="editLookupObject.date" :format="'dd/MM/yyyy'"></Datepicker>
        </div>
      </div>
      <div class="row mb-3 mt-3">
        <div class="col-md-12 form-group">
          <label class="d-block">Sign:</label>
          <Dropdown v-model="editLookupObject.ascendantSignId" :options="signs" filterMatchMode="contains"
                    optionLabel="name" optionValue="id" :filter="true"/>
        </div>
      </div>

      <div class="row mb-3 mt-3">
        <div class="col-md-12 form-group">
          <label class="d-block">House:</label>
          <Dropdown v-model="editLookupObject.houseId" :options="houses" filterMatchMode="startsWith" optionLabel="name"
                    optionValue="id" :filter="true"/>
        </div>
      </div>
      <div class="row mb-3 mt-3">
        <div class="col-md-12 form-group">
          <label class="d-block">Score:</label>
          <InputNumber v-model="editLookupObject.score" showButtons buttonLayout="horizontal"/>
        </div>
      </div>
      <div class="modal-footer">
        <Button label="Cancel" class="p-button-outlined" @click="showEditModal = false"/>
        <Button label="Delete" icon="pi pi-trash" iconPos="left" class="p-button-danger" @click="deleteLookup()"/>
        <Button label="Save" icon="pi pi-save" iconPos="left" @click="lookupEdit()"/>
      </div>
    </Dialog>

    <Dialog header="New Lookup" v-model:visible="showNewModal" modal>
      <div class="row mb-3 mt-3">
        <div class="col-md-12 form-group">
          <label class="d-block">DATE:</label>
          <Datepicker v-model="newLookupObject.date" :format="'dd/MM/yyyy'"></Datepicker>
        </div>
      </div>

      <div class="row mb-3 mt-3">
        <div class="col-md-5 form-group me-5">
          <label class="d-block">Sign:</label>
          <Dropdown v-model="newLookupObject.signId" :options="signs" optionLabel="name" optionValue="id"
                    filterMatchMode="contains" :filter="true"/>
        </div>
      </div>

      <div class="row mb-3 mt-3">
        <div class="col-md-12 form-group">
          <label class="d-block">House:</label>
          <Dropdown v-model="newLookupObject.houseId" :options="houses" optionLabel="name" optionValue="id"
                    filterMatchMode="contains" :filter="true"/>
        </div>
      </div>
      <div class="row mb-3 mt-3">
        <div class="col-md-12 form-group">
          <label class="d-block">Score:</label>
          <InputNumber v-model="newLookupObject.score"/>
        </div>
      </div>
      <div class="modal-footer">
        <Button label="Cancel" class="p-button-outlined" @click="showNewModal = false"/>
        <Button label="Add" icon="pi pi-save" iconPos="left" @click="lookupAdd()"/>
      </div>
    </Dialog>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import dayjs from "dayjs";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Button from "primevue/button";
// import Divider from "primevue/divider";
// import InputSwitch from "primevue/inputswitch";
import InputNumber from "primevue/inputnumber";
import Toast from 'primevue/toast';
// import MultiSelect from 'primevue/multiselect';
export default {
  name: "Calendar",
  components: {
    Header,
    FullCalendar,
    Dropdown,
    Dialog,
    Datepicker,
    Button,
    // Divider,
    // InputSwitch,
    InputNumber,
    Toast,
    // MultiSelect
  },
  data() {
    return {
      tasks: [],
      events: [],
      calendarOptions: {
        themeSystem: "bootstrap5",
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridWeek",
        },
        dayHeaderFormat: {day: "numeric", weekday: "short"},
        initialView: "dayGridWeek",
        titleFormat: {month: "long", day: "numeric"},
        firstDay: 1,
        nowIndicator: true,
        editable: false,
        selectable: false,
        selectMirror: false,
        dayMaxEvents: true,
        weekends: true,
        contentHeight: 600,
        aspectRatio: 0.3,
        height: 650,
        eventClick: this.handleEventClick,
        datesSet: this.handleWeekChange,
        // select: this.handleDateSelect,
        showNonCurrentDates: false,
        initialEvents: [],
        // allDaySlot: false,
      },
      prevEvents: [],
      calendarArgs: null,
      editLookupObject: {},
      showEditModal: false,
      signs: [],
      students: [],
      programs: [],
      newLookupObject: {
        date: null,
        programId: "",
        tutorId: "",
        students: [{id: ""}, {id: ""}, {id: ""}],
        sessionCount: 1,
      },
      showNewModal: false,
      selectedTutors: [],
    };
  },
  mounted() {
    this.$appAxios
        .get("admin/global/signs")
        .then((resp) => {
          this.signs = resp.data;
        });
    this.$appAxios
        .get("admin/global/houses")
        .then((resp) => {
          this.houses = resp.data;
        });
  },
  computed: {},
  methods: {
    formatDate(date) {
      return dayjs(date).format("DD/MM/YYYY");
    },
    handleEventClick(clickInfo) {
      var id = clickInfo.event.id.substring(
          clickInfo.event.id.indexOf("_") + 1
      );

      this.$appAxios
          .get("admin/contentLookUp/" + id)
          .then((resp) => {
            this.editLookupObject = resp.data;
            this.showEditModal = true;
          });

    },
    handleWeekChange(arg) {

      if (arg) {
        this.calendarArgs = arg;
      }

      this.getLookups();
    },
    getLookups() {
      this.calendarArgs.view.calendar.removeAllEvents();
      this.$appAxios
          .post("admin/contentLookUp/list", {
            start: this.calendarArgs.start,
            end: this.calendarArgs.end,
          })
          .then((resp) => {

            for (var i = 0; i < resp.data.data.length; i++) {
              var event = resp.data.data[i];
              // var className = "";

              // if (event.IsWorkshop) {
              //   className = "event-workshop";
              // } else {
              //   if (event.IsMakeUp) {
              //     className = "event-makeup";
              //   } else if (event.IsSingle) {
              //     className = "event-single";
              //   } else {
              //     className = "event-group";
              //   }
              // }

              this.calendarArgs.view.calendar.addEvent({
                id: event.id,
                title: event.ascendantSign + " / " + event.house + " / " + event.score,
                start: event.date,
                allDay: true
                //   classNames: [className],
              });
            }
          })
          .catch(() => {
          });
    },
    lookupEdit() {
      this.$appAxios.put(
          "admin/contentLookUp",
          this.editLookupObject
      ).then(() => {
        this.showEditModal = false;
        this.getLookups();
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Lookup Info Edited', life: 3000});

      }).catch((err) => {
        this.showEditModal = false;
        this.$toast.add({severity: 'error', summary: 'Error', detail: err.response.data.error, life: 3000});
      });
    },
    deleteLookup() {
      this.$appAxios
          .delete("admin/contentLookUp/" + this.editLookupObject.id
          )
          .then(() => {
            this.showEditModal = false;
            this.getLookups();
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'Lookup Deleted!', life: 3000});
          }).catch((err) => {
        this.showNewModal = false;
        this.$toast.add({severity: 'error', summary: 'Error', detail: err.response.data.error, life: 3000});

      });
    },
    lookupAdd() {
      this.$appAxios
          .post("admin/contentLookUp", {
            ascendantSignId: this.newLookupObject.signId,
            houseId: this.newLookupObject.houseId,
            score: this.newLookupObject.score,
            date: this.newLookupObject.date,
          })
          .then(() => {
            this.showNewModal = false;
            this.getLookups();
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'New Look Up Added!', life: 3000});
          })
          .catch((err) => {
            this.showNewModal = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: err.response.data.error, life: 3000});

          });

      // this.axios.post(this.$store.state.baseUrl + "session")
    },
    openNewModal() {
      this.newLookupObject = {}
      this.showNewModal = true
    },
    uploader(event) {
      var file = event.files[0];

      let formData = new FormData();
      formData.append("file", file);

      this.$appAxios
          .post("admin/contentLookUp/upload", formData, {
            headers: {
              'Content-Type': file.type,
            }
          })
          .then(() => {
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'File was uploaded successfully',
              life: 3000
            });
            this.getLookups();
          }).catch(() => {
        this.$toast.add({severity: 'error', summary: 'Error', detail: 'Could not upload the file', life: 3000});
      });
    }
  },
};
</script>

<style scoped>
.fc {
  height: calc(100vh - 150px) !important;
}

.fc-event-time {
  display: none !important;
}

.fc-daygrid-event {
  height: 24px;
  cursor: pointer;
}

.event-order {
  background-color: #ffe2e5 !important;
  color: #f64e60 !important;
}

.event-order > .fc-daygrid-event-dot {
  border-color: #f64e60 !important;
}

.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active {
  background-color: #f64e60 !important;
}

.p-dialog .p-dropdown {
  width: 400px;
}

.form-group .p-button-icon-only {
  margin-top: 25px;
}
</style>
