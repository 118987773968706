<template>
  <Dialog header="New Content" v-model:visible="showModal" modal :style="{ 'width': '40%' }">
    <Form>
      <div class="row mb-3 mt-3">
        <div class="col-md-6 form-group">
          <label class="d-block">Date:</label>
          <Calendar v-model="newContent.date" :disabled-days="[1, 2, 3, 4, 5, 6]" selection-mode="range"
                    format="dd/MM/yyyy" @date-select="onDateSelect($event)"/>
        </div>

        <div class="col-md-12 form-group">
          <label class="d-block">Auto Translate:</label>
          <InputSwitch v-model="newContent.autoTranslate" @change="updateAutoAudio"/>
        </div>
        <div class="col-md-12 form-group">
          <label class="d-block">Auto Audio Languages:</label>
          <MultiSelect v-model="newContent.autoAudioLanguages" :options="supportedLanguages" 
                       optionLabel="name" optionValue="id" placeholder="Select Languages" class="w-100"
                       :disabled="!newContent.autoTranslate"/>
        </div>
        <div class="col-md-12 form-group">
          <label class="d-block">Title:</label>
          <InputText class="w-100" type="text" v-model="newContent.title"/>
        </div>
        <div class="col-md-12 form-group">
          <label class="d-block">Text:</label>
          <Textarea class="w-100" type="text" rows="8" v-model="newContent.text"/>
        </div>
        <div v-if="contentType === 'weekly' || contentType === 'monthly' || contentType === 'cosmic-event' || contentType === 'story'" class="col-md-12 form-group">
          <label class="d-block">Story Prefix:</label>
          <InputText class="w-100" type="text" v-model="newContent.storyPrefix"/>
        </div>
        <div v-if="contentType !== 'weekly'" class="col-12">
          <label class="d-block">Category</label>
          <Dropdown class="w-100" v-model="newContent.category" :options="contentCategories" optionLabel="name"
                    optionValue="id" placeholder="Select Category"/>
        </div>
        <div class="form-group col-md-6">
          <label class="d-block">General:</label>
          <InputSwitch v-model="newContent.isGeneral"/>
        </div>
        <div class="form-group col-md-6">
          <label class="d-block">Premium:</label>
          <InputSwitch v-model="newContent.isPremium"/>
        </div>
        <div class="col-md-6 form-group" v-if="!newContent.isGeneral">
          <label class="d-block">Ascendant Sign:</label>
          <Dropdown class="w-100" v-model="newContent.ascendantSignId" :options="signs" optionLabel="name"
                    optionValue="id" placeholder="Select Sign"/>
        </div>
        <div class="col-md-6 form-group">
          <label class="d-block">Min Age:</label>
          <InputNumber class="w-100" mode="decimal" v-model="newContent.minAge"/>
        </div>
        <div class="col-md-6 form-group">
          <label class="d-block">Max Age:</label>
          <InputNumber class="w-100" mode="decimal" v-model="newContent.maxAge"/>
        </div>
        <div class="col-md-6 form-group">
          <label class="d-block">Gender:</label>
          <Dropdown class="w-100"
                    :options="[{ 'id': 0, 'value': 'All' }, { 'id': 1, 'value': 'Man' }, { 'id': 2, 'value': 'Woman' }]"
                    v-model="newContent.gender" optionLabel="value" optionValue="id"/>
        </div>
        <div class="col-md-6 form-group">
          <label class="d-block">Relationship:</label>
          <MultiSelect class="w-100" :options="relationships" v-model="newContent.relationshipStatusIds"
                       optionLabel="name" optionValue="id"/>
        </div>
        <div class="col-md-6 form-group">
          <label class="d-block">Occupations:</label>
          <MultiSelect class="w-100" v-model="newContent.occupationIds" :options="occupations" optionLabel="name"
                       optionValue="id" placeholder="Select Occupations"/>
        </div>
        <div class="col-md-6 form-group">
          <label class="d-block">Tags:</label>
          <MultiSelect class="w-100" v-model="newContent.categoryIds" :options="categories" optionLabel="name"
                       optionValue="id" placeholder="Select Tags"/>
        </div>
        <div class="col-6" v-if="showNumbers">
          <label class="d-block">Show Numbers:</label>
          <InputSwitch v-model="newContent.showNumbers"/>
        </div>
      </div>
      <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
        {{ msg.content }}
      </Message>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" @click="hideModal">
          Close
        </button>
        <Button label="Save" icon="pi pi-save" iconPos="left" @click="addNewContent"/>
      </div>
    </Form>
  </Dialog>
</template>
<script>
import dayjs from "dayjs";

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
import {mapGetters} from 'vuex'

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    contentType: {
      type: String,
      required: true
    },
    showNumbers: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
  },
  data() {
    return {
      newContent: {
        autoTranslate: false,
        autoAudioLanguages: [],
      },
      messages: [],
      messageCount: 0,
      contentCategories: [{id: 'meditation', name: "Meditation"}, {id: 'ritual', name: "Ritual"}],
      storyPrefix: ""
    }
  },
  computed: {
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds', 'categories', 'supportedLanguages']),
    showModal: {
      get() {
        return this.show;
      },
      set() {
        this.hideModal();
      }
    },
  },
  methods: {
    hideModal() {
      this.$emit('hide');
    },
    addNewContent() {
      if (!(this.newContent.title && this.newContent.date && this.newContent.text && (this.newContent.ascendantSignId != null || this.newContent.isGeneral))) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      var startDate = this.newContent.date[0];
      var startDateUtc = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0)).toISOString();

      var endDate = this.newContent.date[1];
      var endDateUtc = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0)).toISOString();

      if(this.contentType === 'weekly' || this.contentType === 'monthly' || this.contentType === 'cosmic-event' || this.contentType === 'story'){
        this.storyPrefix = this.newContent.storyPrefix
      }else {
        this.storyPrefix = ""
      }

      this.$appAxios.post("admin/content/v2/" + this.contentType, {
        type: this.contentType,
        houseId: this.newContent.houseId,
        ascendantSignId: this.newContent.ascendantSignId,
        score: this.newContent.score,
        minAge: this.newContent.minAge,
        maxAge: this.newContent.maxAge,
        gender: this.newContent.gender,
        relationshipStatusIds: this.newContent.relationshipStatusIds,
        occupationIds: this.newContent.occupationIds,
        startDate: startDateUtc,
        endDate: endDateUtc,
        title: this.newContent.title,
        text: this.newContent.text,
        isGeneral: this.newContent.isGeneral,
        showNumbers: this.newContent.showNumbers,
        category: this.newContent.category,
        isPremium: this.newContent.isPremium,
        categoryIds: this.newContent.categoryIds,
        autoTranslate: this.newContent.autoTranslate,
        storyPrefix: this.storyPrefix,
        autoAudioLanguages: this.newContent.autoTranslate ? this.newContent.autoAudioLanguages : [],
      }).then(() => {
        this.hideModal();
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content added successfully', life: 3000});
        this.$emit('refresh');
      }).catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occured while adding content',
          life: 3000
        });
        this.$emit('refresh');
      });
    },
    onDateSelect(event) {
      var date = dayjs(event).add(7, 'day').toDate();
      this.newContent.date[1] = date;
    },
    updateAutoAudio() {
      if (!this.newContent.autoTranslate) {
        this.newContent.autoAudioLanguages = [];
      }
    },  
  }
}
</script>
<style>
.p-inputswitch {
  vertical-align: middle;
}
</style>