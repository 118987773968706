<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <ConfirmDialog/>
          <DataTable :value="contents" :lazy="true" :paginator="true"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     :rowsPerPageOptions="[5, 10, 20, 50, 100]" dataKey="id" :rows="10" ref="dt"
                     :totalRecords="contentsTotalRecords" :loading="loading" @page="onContentsPage($event)"
                     @sort="onContentsSort($event)" @filter="onContentsFilter($event)" responsiveLayout="scroll"
                     class="p-datatable-striped" v-model:filters="filters" filterDisplay="menu" removableSort
                     :globalFilterFields="['name', 'isActive']"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
              <div class="d-flex justify-content-between">
                <div>
                  <Button type="button" icon="pi pi-plus" label="Add Content" class="p-button-info d-inline me-2"
                          @click="showNewContent()"/>
                  <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
                </div>
                <span class="p-input-icon-left">
                  <i class="pi pi-search"/>
                  <InputText v-model="filters['global'].value" placeholder="Keyword Search" @change="getContents"/>
                </span>
              </div>
            </template>

            <template #empty> No contents found.</template>
            <template #loading> Loading contents data. Please wait.</template>
            <Column field="id" header="Id" ref="id">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.id }}</span>
              </template>
            </Column>
            <Column field="title" header="Title" ref="title">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.title }}</span>
              </template>
            </Column>
            <Column field="text" header="Text" ref="text">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.text }}</span>
              </template>
            </Column>
            <Column field="type" header="Type" ref="type">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.type }}</span>
              </template>
            </Column>
            <Column field="category" header="Category" ref="category">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.category }}</span>
              </template>
            </Column>
            <Column field="actions" header="Actions" ref="actions">
              <template #body="slotProps">
                <Button v-tooltip.left="'Edit Translations'" v-text="'TR'" class="p-button-sm me-2 my-2"
                        :class="{ 'p-button-danger': !slotProps.data.isVerified['tr'], 'p-button-success': slotProps.data.isVerified['tr'] }"
                        @click="showEditLanguage('programGroup', slotProps.data.id, 'tr')"/>
                <Button v-tooltip.left="'Edit Translations'" v-text="'EN'" class="p-button-sm me-2 my-2"
                        :class="{ 'p-button-danger': !slotProps.data.isVerified['en'], 'p-button-success': slotProps.data.isVerified['en'] }"
                        @click="showEditLanguage('programGroup', slotProps.data.id, 'en')"/>
                <Button v-tooltip.left="'Delete Content'" icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                        @click="deleteContent(slotProps.data.id)"/>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>

      <Dialog header="New Content" v-model:visible="showNewContentModal" modal :style="{ 'width': '40%' }">
        <Form>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="d-block">Title:</label>
              <InputText v-model="newContent.title"/>
            </div>
            <div class="col-md-6 mb-3">
              <label class="d-block">Type:</label>
              <MultiSelect v-model="newContent.types" :options="doDontTypes" optionLabel="name" optionValue="id"
                           placeholder="Select Type(s)"/>
            </div>
          </div>
          <div class="col-md-12">
            <label class="d-block">Text:</label>
            <Textarea class="w-100" type="text" rows="5" v-model="newContent.text"/>
          </div>
          <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
            {{ msg.content }}
          </Message>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="showNewDailyContentModal = false">
              Close
            </button>
            <Button label="Save" icon="pi pi-save" iconPos="left" @click="addNewContent()"/>
          </div>
        </Form>
      </Dialog>

      <Dialog header="Edit Language" v-model:visible="showEditLanguageModal" modal :style="{ 'width': '40%' }">
        <Form>
          <div class="col-12">
            <label class="d-block">Verified</label>
            <InputSwitch v-model="editLanguageModel.isVerified"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Title:</label>
            <InputText class="w-100" type="text" v-model="editLanguageModel.title"/>
          </div>
          <div class="row mb-3 mt-3">
            <div class="col-md-12 form-group">
              <label class="d-block">Text:</label>
              <Textarea class="w-100" type="text" rows="5" v-model="editLanguageModel.text"/>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="showEditLanguageModal = false">
              Close
            </button>
            <Button label="Save" icon="pi pi-save" iconPos="left" @click="editLanguage()"/>
          </div>
        </Form>
      </Dialog>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import Textarea from "primevue/textarea";
// import TriStateCheckbox from "primevue/tristatecheckbox";
import MultiSelect from "primevue/multiselect";
//   import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
// import InputSwitch from "primevue/inputswitch";
//   import Datepicker from "@vuepic/vue-datepicker";
//   import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import Toast from 'primevue/toast';
import Message from 'primevue/message';
import ConfirmDialog from 'primevue/confirmdialog';

export default {
  name: "MonthlyContent",
  components: {
    // Form,
    // Field,
    // ErrorMessage,
    Header,
    DataTable,
    Column,
    InputText,
    Textarea,
    Button,
    // TriStateCheckbox,
    MultiSelect,
    //   Dropdown,
    Dialog,
    // InputSwitch,
    //   Datepicker,
    //   InputNumber,
    Toast,
    Message,
    ConfirmDialog
  },
  data() {
    return {
      loading: false,
      contentsTotalRecords: 0,
      contents: [],
      filters: {
        global: {value: null},
        name: {value: null},
      },
      contentsLazyParams: {},
      showNewContentModal: false,
      showEditLanguageModal: false,
      showEditContent: false,
      newContent: {},
      editContentModel: {},
      doDontTypes: [{id: 0, name: 'Do'}, {id: 1, name: 'Don\'t'}],
      editLanguageModel: {},
    };
  },
  mounted() {
    this.contentsLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    this.getContents();
  },
  methods: {
    getContents() {
      this.loading = true;

      var filters = {};

      for (const [key] of Object.entries(this.contentsLazyParams.filters)) {
        filters[key] = this.contentsLazyParams.filters[key].value;
      }

      this.$appAxios
          .post("admin/content/list/do-don't", {
            first: this.contentsLazyParams.first,
            rows: this.contentsLazyParams.rows,
            sortField: this.contentsLazyParams.sortField,
            sortOrder: this.contentsLazyParams.sortOrder,
            page: this.contentsLazyParams.page,
            filters: JSON.stringify(filters),
          })
          .then((resp) => {
            this.contents = resp.data.data;
            this.contentsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onContentsPage(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsSort(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsFilter() {
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.getContents();
    },
    formatDate(dateString) {
      if (dateString == null) {
        return "";
      }

      const date = dayjs(dateString);
      return date.format("DD/MM/YYYY");
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    showNewContent() {
      this.newContent = {}
      this.showNewContentModal = true;
    },
    showEditLanguage(type, id, language) {

      this.$appAxios.get("admin/content/translation/" + id + "/" + language)
          .then(resp => {
            this.editLanguageModel = resp.data;
            this.editLanguageModel.type = type;
            this.showEditLanguageModal = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    editLanguage() {
      if (!(this.editLanguageModel.text && this.editLanguageModel.language)) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      this.$appAxios.put("admin/content/translation",
          {
            id: this.editLanguageModel.id,
            title: this.editLanguageModel.title,
            text: this.editLanguageModel.text,
            language: this.editLanguageModel.language,
            isVerified: this.editLanguageModel.isVerified,
          }
      ).then(() => {
        this.showEditLanguageModal = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Language info updated', life: 3000});
        this.getContents();
      })
          .catch(() => {
            this.showEditLanguageModal = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Language info not updated', life: 3000});
          })
    },
    deleteContent(id) {
      this.$confirm.require({
        message: 'Are you sure you want to delete the content?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$appAxios.delete("admin/content/" + id)
              .then(() => {
                this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content deleted', life: 3000});
                this.getContents();
              })
              .catch(() => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Content could not be deleted',
                  life: 3000
                });
              })
        },
        reject: () => {
        }
      });
    }
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>
  