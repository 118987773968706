<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <ConfirmDialog/>

          <DataTable :value="contents" :lazy="true" :paginator="true"
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     :rowsPerPageOptions="[5, 10, 20, 50, 100]" dataKey="id" :rows="10" ref="dt"
                     :totalRecords="contentsTotalRecords" :loading="loading" v-model:expandedRows="expandedRows"
                     @rowExpand="onContentsExpand" @page="onContentsPage($event)" @sort="onContentsSort($event)"
                     @filter="onContentsFilter($event)" responsiveLayout="scroll" class="p-datatable-striped"
                     v-model:filters="filters" filterDisplay="menu" removableSort
                     :globalFilterFields="['name', 'isActive']"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
              <div class="d-flex justify-content-between">
                <div>
                  <Button type="button" icon="pi pi-plus" label="Add Content" class="p-button-info d-inline me-2"
                          @click="showNewContent()"/>
                  <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)"/>
                </div>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"/>
                    <InputText
                        v-model="filters['global'].value"
                        placeholder="Keyword Search"
                        @change="getContents"
                    />
                  </span>
              </div>
            </template>

            <template #empty> No contents found.</template>
            <template #loading> Loading contents data. Please wait.</template>
            <Column :expander="true" headerStyle="width: 3rem"/>
            <Column field="title" header="Title" ref="title">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.title }}</span>
              </template>
            </Column>
            <Column field="title" header="Text" ref="text">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.text }}</span>
              </template>
            </Column>
            <Column field="date" header="Date" ref="date">
              <template #body="slotProps">
                <span class="m-0">{{
                    formatDate(slotProps.data.startDate) + "-" + formatDate(slotProps.data.endDate)
                  }}</span>
              </template>
            </Column>
            <Column field="ascendant" header="Ascendant" ref="ascendant">
              <template #body="slotProps">
                <span class="m-0">{{ this.signs?.find(x => x.id == slotProps.data.ascendantSignId)?.name }}</span>
              </template>
            </Column>
            <Column field="gender" header="Gender" ref="gender">
              <template #body="slotProps">
                <span class="m-0">{{ this.genders?.find(x => x.id == slotProps.data.gender)?.name }}</span>
              </template>
            </Column>
            <Column field="relationshipStatusIds" header="RelationshipStatus">
              <template #body="slotProps">
                <div v-for="relationshipStatusId in slotProps.data.relationshipStatusIds" :key="relationshipStatusId">
                  <span class="mt-1">{{ this.relationships?.find(x => x.id == relationshipStatusId)?.name }}</span>
                </div>
              </template>
            </Column>
            <Column field="occupation" header="Occupations" ref="slug">
              <template #body="slotProps">
                <div v-for="occupationId in slotProps.data.occupationIds" :key="occupationId">
                  <span class="mt-1">{{ this.occupations?.find(x => x.id == occupationId)?.name }}</span>
                </div>
              </template>
            </Column>
            <Column field="minAge" header="Min Age" ref="minAge">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.minAge }}</span>
              </template>
            </Column>
            <Column field="maxAge" header="Max Age" ref="maxAge">
              <template #body="slotProps">
                <span class="m-0">{{ slotProps.data.maxAge }}</span>
              </template>
            </Column>
            <Column field="actions" header="Actions" ref="actions">
              <template #body="slotProps">
                <Button
                    icon="pi pi-book"
                    class="p-button p-button-sm me-2 my-2"
                    @click="showEditLanguage(slotProps.data.id)"
                />
                <Button icon="pi pi-pencil" class="p-button-warning p-button-sm me-2 my-2"
                        @click="openEditContent(slotProps.data.id)"/>
                <Button icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                        @click="deleteContent(slotProps.data.id)"/>
              </template>
            </Column>
            <template #expansion="slotProps">
              <DataTable :value="slotProps.data.expandedTable" :lazy="true" dataKey="id" :rows="5" ref="dt"
                         responsiveLayout="scroll"
                         class="p-datatable-striped" :paginator="true"
                         paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                         :rowsPerPageOptions="[5, 10, 20, 50, 100]" :totalRecords="subContentsTotalRecords"
                         :loading="loading"
                         @page="onSubContentPage($event)">
                <template #header>
                  <div class="table-header">
                    <Button label="Add Subcontent" icon="pi pi-plus" @click="openSubContentModal"></Button>
                  </div>
                </template>
                <Column field="photoUrl" header="Photo" ref="photoUrl">
                  <template #body="slotProps">
                    <img :src="slotProps.data.photoUrl" style="width:40px"/>
                  </template>
                </Column>
                <Column field="title" header="Title" ref="title"></Column>
                <Column field="text" header="Text" ref="text"></Column>
                <Column field="type" header="Type" ref="type"></Column>
                <Column field="order" header="Order" ref="order"></Column>
                <Column field="actions" header="Actions" ref="actions">
                  <template #body="slotProps">
                    <Button
                        icon="pi pi-book"
                        class="p-button p-button-sm me-2 my-2"
                        @click="openSubContentEditLanguage(slotProps.data.id)"
                    />
                    <Button icon="pi pi-pencil" class="p-button-warning p-button-sm me-2 my-2"
                            @click="openEditSubContent(slotProps.data.id)"/>
                    <Button icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                            @click="deleteSubContent(slotProps.data.id)"/>
                  </template>
                </Column>

              </DataTable>
            </template>
          </DataTable>
        </div>
      </div>

      <AddWeeklyContentModal
          :show="showNewContentModal"
          @hide="showNewContentModal = false"
          @refresh="getContents"
          :content-type="'weekly-suggestion'"
          :show-numbers="true"
      />

      <TranslationsModal
          :show="showEditLanguageModal"
          @hide="showEditLanguageModal = false"
          :contentId="editTranslationContentId"
          :isSubContent="isSubContent"
      />

      <Dialog header="Edit Content" v-model:visible="showEditContent" modal :style="{'width':'40%'}">
        <div class="row mb-3 mt-3">
          <div class="col-md-6 form-group">
            <label class="d-block">Date:</label>
            <Calendar v-model="editContentModel.date" :disabled-days="[0,2,3,4,5,6]" selection-mode="range"
                      dateFormat="dd.mm.yy" @date-select="onDateSelectEdit($event)"/>
          </div>
          <div class="form-group"
               :class="{'col-md-6':!editContentModel.isGeneral, 'col-md-12':editContentModel.isGeneral}">
            <label class="d-block">General:</label>
            <InputSwitch v-model="editContentModel.isGeneral"/>
          </div>
          <div v-if="!editContentModel.isGeneral" class="col-md-6 form-group">
            <label class="d-block">Ascendant Sign:</label>
            <Dropdown v-model="editContentModel.ascendantSignId" :options="signs" optionLabel="name" optionValue="id"
                      placeholder="Select Sign"/>
          </div>
          <div class="col-md-6 form-group">
            <Button label="Choose Image" icon="pi pi-save" iconPos="left" @click="toggleOverlay"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Image:</label>
            <img class="img-fluid mx-2 p-dropdown-content-image-preview" :src="editContentModel.photoUrl"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Category:</label>
            <Dropdown
                class="w-100"
                v-model="editContentModel.category"
                :options="categories"
                optionLabel="name"
                optionValue="id"
                placeholder="Select Category"
            />
          </div>
          <div class="form-group col-md-6">
            <label class="d-block">Show Numbers:</label>
            <InputSwitch v-model="editContentModel.showCount"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Min Age:</label>
            <InputNumber buttonLayout="horizontal" decrementButtonClass="p-button-secondary"
                         incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                         decrementButtonIcon="pi pi-minus"
                         mode="decimal" showButtons v-model="editContentModel.minAge"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Max Age:</label>
            <InputNumber buttonLayout="horizontal" decrementButtonClass="p-button-secondary"
                         incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                         decrementButtonIcon="pi pi-minus"
                         mode="decimal" showButtons v-model="editContentModel.maxAge"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Gender:</label>
            <Dropdown :options="[{'id': 0, 'value':'All'},{'id': 1 , 'value':'Man'}, {'id': 2 , 'value':'Woman'}]"
                      v-model="editContentModel.gender" optionLabel="value" optionValue="id"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Relationship:</label>

            <MultiSelect v-model="editContentModel.relationshipStatusIds" :options="[{'id': 0, 'value':'All'},{'id': 1 , 'value':'Single'},
                                  {'id': 2 , 'value':'Married'}, {'id': 3 , 'value':'Divorced'},
                                  {'id': 4 , 'value':'Engaged'},{'id': 5 , 'value':'In Relationship'},
                                  {'id': 6 , 'value':'Complicated'},
                                  {'id': 7 , 'value':'Widowed'},
                                  {'id': 8 , 'value':'Open Relationship'}]" optionLabel="value"
                         optionValue="id" placeholder="Select Relationship"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Occupations:</label>
            <MultiSelect v-model="editContentModel.occupationIds" :options="occupations" optionLabel="name"
                         optionValue="id" placeholder="Select Occupations"/>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="showEditContent = false">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="editContent()"/>
        </div>
      </Dialog>

      <Dialog header="New Sub Content" v-model:visible="showNewSubContentModal" modal :style="{'width':'40%'}">
        <Form>
          <div class="row mb-3 mt-3">

            <div class="col-md-6 form-group">
              <Button label="Choose Image" icon="pi pi-save" iconPos="left" @click="toggleOverlay"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Image:</label>
              <img class="img-fluid mx-2 p-dropdown-content-image-preview" :src="newSubContent.photoUrl"/>
            </div>
            <div class="col-md-12 form-group">
              <label class="d-block">Title:</label>
              <InputText class="w-100" type="text" v-model="newSubContent.title"/>
            </div>
            <div class="col-md-12 form-group">
              <label class="d-block">Text:</label>
              <Textarea class="w-100" type="text" rows="8" v-model="newSubContent.text"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Order:</label>
              <InputNumber buttonLayout="horizontal" decrementButtonClass="p-button-secondary"
                           incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus" mode="decimal" showButtons v-model="newSubContent.order"/>
            </div>
            <div class="col-md-6 form-group">
              <label class="d-block">Type:</label>
              <Dropdown v-model="newSubContent.type" :options="[{id : 1, name: '1'},{id : 2, name: '2'}]"
                        optionLabel="name" optionValue="id" placeholder="Select Type"/>
            </div>
          </div>
          <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
            {{ msg.content }}
          </Message>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="showNewSubContentModal = false">
              Close
            </button>
            <Button label="Save" icon="pi pi-save" iconPos="left" @click="addNewSubContent"/>
          </div>
        </Form>
      </Dialog>

      <Dialog header="Sub Content Edit" v-model:visible="showEditSubContent" modal :style="{'width':'40%'}">
        <div class="row mb-3 mt-3">
          <div class="col-md-6 form-group">
            <Button label="Choose Image" icon="pi pi-save" iconPos="left" @click="toggleOverlay"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Image:</label>
            <img class="img-fluid mx-2 p-dropdown-content-image-preview" :src="editSubContentModel.photoUrl"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Order:</label>
            <InputNumber buttonLayout="horizontal" decrementButtonClass="p-button-secondary"
                         incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                         decrementButtonIcon="pi pi-minus"
                         mode="decimal" showButtons v-model="editSubContentModel.order"/>
          </div>
          <div class="col-md-6 form-group">
            <label class="d-block">Type:</label>
            <Dropdown v-model="editSubContentModel.type" :options="[{id : 1, name: '1'},{id : 2, name: '2'}]"
                      optionLabel="name" optionValue="id" placeholder="Select Type"/>
          </div>
        </div>
        <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
          {{ msg.content }}
        </Message>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="showNewSubContentModal = false">
            Close
          </button>
          <Button label="Save" icon="pi pi-save" iconPos="left" @click="editSubContent"/>
        </div>
      </Dialog>

      <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 500px">
        <!-- <FileUpload mode="basic" name="file[]" :url="`${this.$store.state.baseUrl}content/upload-image`" accept="image/*" class="mb-3" choose-label="New Image"/> -->
        <DataTable :value="images" v-model:selection="newSubContent.photoUrl" selectionMode="single" :paginator="true"
                   :rows="5" @rowSelect="onImageSelect" responsiveLayout="scroll">
          <Column header="Image" style="width: 20%">
            <template #body="slotProps">
              <img style="width : 60px" :src="slotProps.data.id"/>
            </template>
          </Column>
        </DataTable>
      </OverlayPanel>

    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import dayjs from "dayjs";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import InputNumber from "primevue/inputnumber";
import Toast from 'primevue/toast';
import Message from 'primevue/message';
import ConfirmDialog from 'primevue/confirmdialog';
import AddWeeklyContentModal from "../Modals/AddWeeklyContentModal.vue";
import TranslationsModal from "../Modals/TranslationsModal.vue";

export default {
  name: "Workshops",
  components: {
    Header,
    DataTable,
    Column,
    InputText,
    Textarea,
    Button,
    MultiSelect,
    Dropdown,
    Dialog,
    InputNumber,
    Toast,
    Message,
    ConfirmDialog,
    AddWeeklyContentModal,
    TranslationsModal,
  },
  data() {
    const schema = {
      price: "required|numeric|regex",
    };

    return {
      loading: false,
      contentsTotalRecords: 0,
      contents: [],
      filters: {
        global: {value: null},
        name: {value: null},
      },
      contentsLazyParams: {},
      schema,
      expandedRows: {},
      genders: [{id: 0, name: "All"}, {id: 1, name: "Man"}, {id: 2, name: "Woman"}],
      images: [],
      showNewContentModal: false,
      showEditLanguageModal: false,
      showEditContent: false,
      newContent: {
        title: "",
        text: "",
        score: 1,
        houseId: null,
        ascendantSignId: null,
        gender: 0,
        minAge: 0,
        maxAge: 100,
        relationshipStatus: 0,
        photoUrl: ""
      },
      editContentModel: {},
      messages: [],
      messageCount: 0,
      subContents: [],
      subContentsTotalRecords: 0,
      showNewSubContentModal: false,
      newSubContent: {},
      editLanguageModel: {},
      showImagesModal: false,
      showEditSubContent: false,
      editSubContentModel: {},
      categories: [{id: 'meditation', name: "Meditation"}, {id: 'ritual', name: "Ritual"}],
      editTranslationContentId: 0,
      isSubContent: false,
    };
  },
  mounted() {
    this.contentsLazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    this.subContentsLazyParams = {
      first: 0,
      rows: 5,
    }


    for (var i = 154; i < 260; i++) {
      this.images.push({
        id: "https://cdn.astopia.com/icons/" + i + ".jpg",
        name: ""
      })
    }

    this.getContents();
  },
  computed: {
    relationships() {
      return this.$store.relationshipStatuses;
    },
    occupations() {
      return this.$store.occupations;
    },
    houses() {
      return this.$store.houses;
    },
    signs() {
      return this.$store.signs;
    }
  },
  methods: {
    getContents() {
      this.loading = true;

      var filters = {};

      for (const [key] of Object.entries(this.contentsLazyParams.filters)) {
        filters[key] = this.contentsLazyParams.filters[key].value;
      }

      this.$appAxios
          .post("admin/content/list/weekly-suggestion", {
            first: this.contentsLazyParams.first,
            rows: this.contentsLazyParams.rows,
            sortField: this.contentsLazyParams.sortField,
            sortOrder: this.contentsLazyParams.sortOrder,
            page: this.contentsLazyParams.page,
            filters: JSON.stringify(filters),
          })
          .then((resp) => {
            this.contents = resp.data.data;
            this.contentsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    onContentsPage(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsSort(event) {
      this.contentsLazyParams = event;
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsFilter() {
      this.contentsLazyParams.filters = this.filters;
      this.getContents();
    },
    onContentsExpand(event) {
      // this.subContentLazyParams.id = event.data.id;
      this.getSubContents(event.data.id);
    },
    clearFilter() {
      for (const [key] of Object.entries(this.filters)) {
        this.filters[key].value = null;
      }
      this.getContents();
    },
    formatDate(dateString) {
      if (dateString == null) {
        return "";
      }
      const date = dayjs(dateString);
      return date.format("DD/MM/YYYY");
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    showNewContent() {
      this.showNewContentModal = true;
    },
    showEditLanguage(id) {
      this.editTranslationContentId = id;
      this.showEditLanguageModal = true;
    },
    addNewContent() {
      if (!(this.newContent.title && this.newContent.date && this.newContent.text && (this.newContent.ascendantSignId != null || this.newContent.isGeneral))) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      var startDate = this.newContent.date[0];
      var startDateUtc = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0)).toISOString();

      var endDate = this.newContent.date[1];
      var endDateUtc = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0)).toISOString();

      this.$appAxios.post("admin/content/weekly-suggestion", {
        type: "weekly-suggestion",
        houseId: this.newContent.houseId,
        ascendantSignId: this.newContent.ascendantSignId,
        score: this.newContent.score,
        minAge: this.newContent.minAge,
        maxAge: this.newContent.maxAge,
        gender: this.newContent.gender,
        relationshipStatus: this.newContent.relationshipStatus,
        occupationIds: this.newContent.occupationIds,
        startDate: startDateUtc,
        endDate: endDateUtc,
        title: this.newContent.title,
        text: this.newContent.text,
        isGeneral: this.newContent.isGeneral,
        photoUrl: this.newContent.photoUrl,
        showCount: this.newContent.showCount,
        category: this.newContent.category,
      }).then(() => {
        this.showNewContentModal = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content added successfully', life: 3000});
        this.getContents();
      }).catch(() => {
        this.showNewContentModal = false;

        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occured while adding content',
          life: 3000
        });
      });
    },
    openEditContent(id) {
      this.$appAxios.get("admin/content/" + id)
          .then(resp => {
            this.editContentModel = resp.data;
            this.editContentModel.date = [dayjs(resp.data.startDate).toDate(), dayjs(resp.data.endDate).toDate()];
            this.showEditContent = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    editContent() {
      var startDate = this.editContentModel.date[0];
      var startDateUtc = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0)).toISOString();

      var endDate = this.editContentModel.date[1];
      var endDateUtc = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0)).toISOString();

      this.$appAxios.put("admin/content",
          {
            id: this.editContentModel.id,
            ascendantSignId: this.editContentModel.ascendantSignId,
            houseId: this.editContentModel.houseId,
            gender: this.editContentModel.gender,
            minAge: this.editContentModel.minAge,
            maxAge: this.editContentModel.maxAge,
            score: this.editContentModel.score,
            relationshipStatusIds: this.editContentModel.relationshipStatusIds,
            occupationIds: this.editContentModel.occupationIds,
            startDate: startDateUtc,
            endDate: endDateUtc,
            type: "weekly-suggestion",
            isGeneral: this.editContentModel.isGeneral,
            photoUrl: this.editContentModel.photoUrl,
            showCount: this.editContentModel.showCount,
            category: this.editContentModel.category,
            storyPrefix: "",
          }
      ).then(() => {
        this.showEditContent = false;
        this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content updated', life: 3000});
        this.getContents();
      })
          .catch(() => {
            this.showEditContent = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Content not updated', life: 3000});
          })
    },
    deleteContent(id) {
      this.$confirm.require({
        message: 'Are you sure you want to delete the content?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$appAxios.delete("admin/content/" + id)
              .then(() => {
                this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content deleted', life: 3000});
                this.getContents();
              })
              .catch(() => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Content could not be deleted',
                  life: 3000
                });
              })
        },
        reject: () => {
        }
      });
    },
    onSubContentPage(event) {
      this.subContentsLazyParams = event;
      this.subContentsLazyParams.filters = this.filters;
      this.getSubContents();
    },
    getSubContents(id) {
      if (!id) return;

      this.$appAxios
          .post("admin/content/sub-contents/" + id, {
            first: this.subContentsLazyParams.first,
            rows: this.subContentsLazyParams.rows,
          })
          .then((resp) => {
            this.subContents = resp.data.data;
            this.contents.find(d => d?.id === id).expandedTable = resp.data.data
            this.subContentsTotalRecords = resp.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    openSubContentModal() {
      this.newSubContent = {};
      this.showNewSubContentModal = true;
    },
    addNewSubContent() {
      if (!((this.newSubContent.type == 1 && this.newSubContent.photoUrl) || this.newSubContent.type == 2)) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      if (!(this.newSubContent.title && this.newSubContent.text)) {
        this.messages = [{severity: 'warn', content: 'Required fields must be entered.', id: this.messageCount++}];
        return;
      }
      this.newSubContent.contentId = Object.entries(this.expandedRows)[0][0];
      this.$appAxios
          .post("admin/content/sub-content", this.newSubContent)
          .then(() => {
            this.showNewSubContentModal = false;
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'Sub content added', life: 3000});
            this.getSubContents(this.newSubContent.contentId);
          })
          .catch(() => {
            this.loading = false;
          });
    },
    deleteSubContent(id) {
      this.$confirm.require({
        message: 'Are you sure you want to delete the content?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$appAxios.delete("admin/content/sub-content/" + id)
              .then(() => {
                this.$toast.add({severity: 'success', summary: 'Success', detail: 'Content deleted', life: 3000});
                this.getSubContents();
              })
              .catch(() => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Content could not be deleted',
                  life: 3000
                });
              })
        },
        reject: () => {
        }
      });
    },
    toggleOverlay(event) {
      this.$refs.op.toggle(event);
    },
    onImageSelect(event) {
      this.$refs.op.hide();
      this.newSubContent.photoUrl = event.data.id;
      this.editSubContentModel.photoUrl = event.data.id;
      this.newContent.photoUrl = event.data.id;
      this.editContentModel.photoUrl = event.data.id;
    },
    openEditSubContent(id) {
      this.$appAxios.get("admin/content/sub-content/" + id)
          .then((resp) => {
            this.editSubContentModel = resp.data;
            this.showEditSubContent = true;
          })
          .catch(() => {
          });
    },
    editSubContent() {
      this.$appAxios.put("admin/content/sub-content/" + this.editSubContentModel.id, this.editSubContentModel)
          .then(() => {
            this.showEditSubContent = false;
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'Sub content updated', life: 3000});
            this.getSubContents();
          })
          .catch(() => {
            this.showEditSubContent = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Sub content not updated', life: 3000});
          })
    },
    openSubContentEditLanguage(id) {
      this.$appAxios.get("admin/content/sub-content/translations/" + id)
          .then(resp => {
            this.editLanguageModel = resp.data;
            this.editLanguageModelisSubContent = true;
            this.editTranslationContentId = id;
            this.isSubContent = true;
            this.showEditLanguageModal = true;
          })
          .catch(err => {
            console.log(err)
          });
    },
    onDateSelect(event) {
      var date = dayjs(event).add(7, 'day').toDate();
      this.newContent.date[1] = date;
    },
    onDateSelectEdit(event) {
      var date = dayjs(event).add(7, 'day').toDate();
      this.editContentModel.date[1] = date;
    }
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}

.p-dropdown-content-image {
  width: 75px;
  height: 75px;
  padding: 10px;
}

.p-dropdown-content-image-preview {
  width: 120px;
  height: 120px;
}
</style>
  